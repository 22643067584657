import React from 'react'
import cx from 'classnames'
import { PageProps, HeadProps } from 'gatsby'

import { DynamicImage, Icon } from '../atoms'
import { Layout, SEO } from '../organisms/common'
import { SideContainer, BottomProfile } from '../organisms/blog'

import convertToExcerpt from '../../utils/convertToExcerpt'

import * as styles from './blog-post.module.scss'
import 'prismjs/themes/prism-tomorrow.css'

type Props = {
  pageContext: {
    post: Pick<
      Queries.ContentfulPost,
      'id' | 'title' | 'slug' | 'content' | 'thumbnail' | 'createdAt'
    >
  }
} & PageProps

const BlogPost: React.FC<Props> = ({ pageContext, location }) => {
  const { post } = pageContext

  const html = post.content?.childMarkdownRemark?.html || ''
  const tableOfContents = post.content?.childMarkdownRemark?.tableOfContents || ''

  const regForAnchor = /href="(.*)">/g
  const convertedHTML = html.replace(
    regForAnchor,
    'href="$1" target="_blank" rel="noopener noreferrer">',
  )

  return (
    <Layout path={location.pathname || '/blog/'}>
      {post.thumbnail?.id && (
        <div className={styles.thumbnailContainer}>
          <DynamicImage className={styles.thumbnail} filename={post.thumbnail.id} alt="thumbnail" />
          <div className={styles.titleBox}>
            <h1 className={styles.title}>{post.title}</h1>
            <p className={styles.createdAt}>
              <Icon
                className={styles.calendar}
                color="var(--black-color)"
                icon="calendar"
                size={24}
              />
              {post.createdAt}
            </p>
          </div>
        </div>
      )}
      <section className={cx(styles.postSection, 'container')}>
        <div className={styles.postContainer}>
          <div className={styles.post} dangerouslySetInnerHTML={{ __html: convertedHTML }} />
          <BottomProfile />
        </div>
        <SideContainer className={styles.sideContainer} tableOfContents={tableOfContents} />
      </section>
    </Layout>
  )
}

export default BlogPost

type SeoProps = {
  pageContext: {
    post: Pick<
      Queries.ContentfulPost,
      'id' | 'title' | 'slug' | 'content' | 'thumbnail' | 'createdAt'
    >
  }
} & HeadProps

export const Head: React.FC<SeoProps> = ({ pageContext }) => {
  const { post } = pageContext
  const thumbnailUrl = post.thumbnail?.file?.url ? `https:${post.thumbnail.file.url}` : undefined
  const postUrl = `/blog/${post.slug}/`
  const datePublised = post.createdAt ? new Date(post.createdAt) : undefined

  const description = convertToExcerpt(post.content?.childMarkdownRemark?.excerpt || 'description')

  return (
    <SEO
      title={post.title || 'title'}
      url={postUrl}
      description={description}
      image={thumbnailUrl}
      datePublished={datePublised}
      isBlogPost
    />
  )
}
