// extracted by mini-css-extract-plugin
export var calendar = "blog-post-module--calendar--mFYqY";
export var createdAt = "blog-post-module--createdAt--TRURT";
export var post = "blog-post-module--post--eB4rA";
export var postContainer = "blog-post-module--postContainer--IrF3Y";
export var postSection = "blog-post-module--postSection--RZYol";
export var sideContainer = "blog-post-module--sideContainer--dR6E7";
export var thumbnail = "blog-post-module--thumbnail--y2PyC";
export var thumbnailContainer = "blog-post-module--thumbnailContainer--EsGeY";
export var title = "blog-post-module--title--brmKS";
export var titleBox = "blog-post-module--titleBox--27ufa";